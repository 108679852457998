<template>
    <div>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Helper from "@utils/misc";
export default {
    name: 'Logout',
    methods: mapActions(['logoutUser', 'clearFeeSessionData', 'setTokens', 'setCurrentUser', 'setCurrentInstitution']),
    computed: {
        ...mapGetters([]),
    },
    async created() {
        // check if logging from a subordinate institution
        var parentData;
        if(this.tempParentData != null){
            parentData = Helper.clone(this.tempParentData);
        }
        await this.logoutUser();
        this.clearFeeSessionData();
        if(parentData != null){
            // store variables in state
            this.setTokens({access: parentData.accessToken, refresh: parentData.refreshToken});
            this.setCurrentUser(parentData.currentUser);
            this.setCurrentInstitution(parentData.currentInstitution);
            // transfer to franchise dashboard
            this.$router.push({ name: 'FranchiseDashboard' });
            return;
        }

        this.$router.push({ name: 'LoginInstitute' });

    }
}
</script>